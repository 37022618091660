import firebase from "../firebase/firebase"

function setDataonFirestore(email, title, image, slug) {
  const db = firebase.firestore()
  //get user id from redux store and create document using user id- replace partners
  return async dispatch => {
    db.collection("users")
      .doc(email)
      .collection("SavedArticles")
      .doc(slug)
      .set(
        {
          title: `${title}`,
          image: `${image}`,
          slug: `${slug}`,
          SavedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
    dispatch({
      type: "SET_DATA",
    })
  }
}
function removeArticleFromFirebase(email, slug) {
  const db = firebase.firestore()
  //get user id from redux store and create document using user id- replace partners
  return async dispatch => {
    db.collection("users")
      .doc(email)
      .collection("SavedArticles")
      .doc(slug)
      .delete()
      .then(() => {
        console.log("email", email)
        const getFirestoreData = email => dispatch(getDatafromFirestore(email))
        getFirestoreData(email)
      })
  }
}
//TODO:  prevent adding articles twice- done: test with getFunction

function loginFirebase(email, password) {
  return async dispatch => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        let email = user.user.email
        dispatch({
          type: "LOGIN",
          payload: user,
        })
        const getFirestoreData = email => dispatch(getDatafromFirestore(email))
        getFirestoreData(email)
      })
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: error.message,
        })
      })
  }
}

function getDatafromFirestore(email) {
  return async dispatch => {
    const db = firebase.firestore()
    db.collection("users")
      .doc(email)
      .collection("SavedArticles")
      .get()
      .then(querySnapshot => {
        let data = []
        querySnapshot.forEach(doc => {
          data.push(doc.data())
        })
        dispatch({
          type: "GET_DATA",
          payload: data,
        })
      })
  }
}

function signUpFirebase(email, password, firstName, lastName) {
  return dispatch => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        user = firebase.auth().currentUser
        if (user) {
          user.updateProfile({
            displayName: `${firstName} ${lastName}`,
          })
          dispatch({
            type: "SIGN_UP",
            payload: { user, error: "" },
          })
        }
      })
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: error.message,
        })
      })
  }
}

function logOutFirebase(email, password) {
  return dispatch => {
    firebase
      .auth()
      .signOut()
      .then(
        dispatch({
          type: "LOGOUT",
          payload: false,
        })
      )
      .catch(error => {
        dispatch({
          type: "ERROR",
          payload: error.message,
        })
      })
  }
}

function authState() {
  return async dispatch => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        dispatch({
          type: "SET_ONLINE_STATUS",
          payload: user,
        })
      } else {
        dispatch({
          type: "SET_OFFLINE_STATUS",
        })
      }
    })
  }
}

export {
  getDatafromFirestore,
  setDataonFirestore,
  loginFirebase,
  logOutFirebase,
  authState,
  signUpFirebase,
  removeArticleFromFirebase,
}
