import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import { SearchOutlined } from "@material-ui/icons"
import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import MenuSvg from "../assets/images/outline-menu-24px.svg"
import Avatar from "@material-ui/core/Avatar"
import { useSelector } from "react-redux"

const Header = props => {
  const { email } = useSelector(state => ({
    ...state.mainReducer.user.user, //extract email address from nested user object
  })) //select different slices of state controlled by reducers

  const [menuToggle, setmenuToggle] = useState(false)
  const toggleMenu = () => {
    setmenuToggle(!menuToggle)
  }

  return (
    <div className="main-nav">
      <AppBarCustom position="static">
        <ToolbarCustom>
          <Logo>
            <Link to="/">
              <span className="mi">mi</span>{" "}
              <span className="aggrego">aggrego</span>
            </Link>
          </Logo>
          <Menu>
            <Link to="/sections/recipes">
              <Button className="menu"> Recipes</Button>
            </Link>
            <Link to="/sections/wines">
              <Button className="menu"> Wines</Button>
            </Link>
            <Link to="/sections/Top10Lists">
              <Button className="menu"> Miaggrego's Finest</Button>
            </Link>
            <Link to="/sections/Blogs">
              <Button className="menu"> Blogs</Button>
            </Link>

            <Link to="/search">
              <Button className="menu">
                {" "}
                <SearchOutlined />
              </Button>
            </Link>

            {email && (
              <Link to="/dashboard">
                <Button className="menu">
                  <Avatar>{email.charAt(0)}</Avatar>
                </Button>
              </Link>
            )}

            <Button>
              <img
                onClick={toggleMenu}
                alt="menu"
                className="burger"
                src={MenuSvg}
              />
            </Button>
          </Menu>
        </ToolbarCustom>
      </AppBarCustom>

      <MenuDropDown className={`${menuToggle}`}>
        <Link to="/sections/recipes">
          <Button className={`${menuToggle} menu`}> Recipes</Button>
        </Link>
        <Link to="/sections/wines">
          <Button className={`${menuToggle} menu`}> Wines</Button>
        </Link>
        <Link to="/sections/Top10Lists">
          <Button className={`${menuToggle} menu`}> Miaggrego's Finest</Button>
        </Link>
        <Link to="/sections/Blogs">
          <Button className={`${menuToggle} menu`}> Blogs</Button>
        </Link>
        <Link to="/search">
          <Button className={`${menuToggle} menu`}>
            <SearchOutlined />
          </Button>
        </Link>
        {/* {email &&
          <Link to="/dashboard" >
            <Button className="menu">
              <Avatar >{email.charAt(0)}</Avatar>
            </Button>
          </Link>

        } */}
      </MenuDropDown>
    </div>
  )
}

export default Header

//styled components
const AppBarCustom = styled(AppBar)`
  border-bottom: 1px solid #e0e0e0;
  box-shadow: none !important;
  font-family: "GT-Walsheim-Regular", sans-serif;
  font-weight: 300;
  color: #242424;
  line-height: 2rem;
  margin: auto;
`

const ToolbarCustom = styled(Toolbar)`
  background: #fff;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    padding-top: 23px;
    padding-left: 50px;
  }
  ul {
    margin-left: calc(40vw - 10px);

    padding-top: 20px;
  }
  button {
    padding: 0px 10px;
  }
  .burger {
    display: none;
  }
  .menu {
    font-family: "GT-Walsheim-Regular", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #323232;
  }
  @media (min-width: 768px) and (max-width: 868px) {
    .menu {
      font-size: 0.9rem;
      padding: 0px 0px 0px 10px;
    }
  }

  @media (max-width: 1060px) {
    .menu {
      display: none;
    }

    .burger {
      display: block;
    }
  }
`

const Logo = styled.div`
  min-width: 180px;
  margin-top: 10px;
  margin-left: 1.5%;
  a {
    text-decoration: none;
  }
  .mi {
    font-family: "GT-Walsheim-Bold", sans-serif;
    font-weight: 600;
    font-size: 2.7rem;
    color: #333333;
    margin-right: -5px;
    letter-spacing: -1px;
  }
  .aggrego {
    font-family: "GT-Walsheim-Bold", sans-serif;
    color: #333333;
    font-weight: 600;
    font-size: 2.7rem;
    background-color: #ffff00;
    letter-spacing: -1px;
  }
`
const Menu = styled.div`
  .true {
    display: block;
  }
  .false {
    display: none;
  }
`

const MenuDropDown = styled.div`
  font-family: "GT-Walsheim-Bold", sans-serif;
  background-color: #9fa0a14a;
  display: flex;
  flex-direction: column;
  align-items: center;

  .true {
    display: block;
  }
  .false {
    display: none;
  }
`
