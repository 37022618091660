import React from "react"
import styled from "styled-components"
import Facebook from "../assets/images/facebook.svg"
import Insta from "../assets/images/insta.svg"
import Twitter from "../assets/images/twitter.svg"
import Grid from "@material-ui/core/Grid"
import Layout from "./layout"
import Img from "gatsby-image"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import { Link, graphql } from "gatsby"
const NavBar = styled.div`
  background: #000;
  font-weight: 500;
  font-family: "GT-Walsheim-Medium", sans-serif;
  color: #fff;
  font-size: 12px;
  border-top: 0.5px solid #3b3b3b;
  .logo {
    margin-left: 10px;
  }
  a {
    color: #fff;
  }
  a:link {
    text-decoration: none;
  }

  height: 75px;
  padding: 25px 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  #footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
  }
  #footer-links a {
    color: #fff;
    padding: 0px 15px;
  }

  @media (max-width: 794px) {
    #footer-links {
      display: none;
    }
  }
`

const Footer = props => {
  // const classes = NavBar();

  return (
    <>
      <FooterGrid>
        <Container maxWidth="md">
          <Grid
            justify="space-between"
            alignItems="flex-start"
            className="links"
            container
            lg={12}
          >
            <Grid item lg={2} xs={6} sm={4}>
              <ul>
                <li>
                  <h5>Company</h5>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Our Team</a>
                </li>
                <li>
                  <a href="#">Events</a>
                </li>
                <li>
                  <a href="#">In the news</a>
                </li>
                <li>
                  <a href="#">Jobs</a>
                </li>
              </ul>
            </Grid>
            <Grid item lg={2} xs={6} sm={4}>
              <ul>
                <li>
                  <h5>Explore</h5>
                </li>
                <li>
                  <a href="#">Recipes</a>
                </li>
                <li>
                  <a href="#">Wines</a>
                </li>
                <li>
                  <a href="#">Lists</a>
                </li>
                <li>
                  <a href="#">Blogs</a>
                </li>
                <li>
                  <Link to={"/tags/"}>Categories</Link>
                </li>
              </ul>
            </Grid>
            <Grid item lg={2} xs={6} sm={4}>
              <ul>
                <li>
                  <h5>Company</h5>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Our Team</a>
                </li>
                <li>
                  <a href="#">Events</a>
                </li>
                <li>
                  <a href="#">In the news</a>
                </li>
                <li>
                  <a href="#">Jobs</a>
                </li>
              </ul>
            </Grid>
            <Grid item lg={4} xs={12} sm={4}>
              <h4>miaggrego newsletter</h4>
              <p>
                Subscribe to our newsletter to get our best tips in your inbox
                daily
              </p>
              <TextField
                id="outlined-size-small"
                size="small"
                defaultValue=" "
                variant="outlined"
                onChange={e => console.log(e.target.value)}
              />
              <Button
                variant="outlined"
                type="submit"
                onClick={() => console.log("")}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </Container>
      </FooterGrid>
      <NavBar position="static">
        <a className="logo" href="#" title=" ©miaggrego" rel="home">
          2020 © miaggrego
        </a>
        <div id="footer-links">
          <a href="#">Terms</a>
          <span>|</span>
          <a href="#"> Privacy</a>
          <span>|</span>
          <a href="#"> Code of Conduct</a>
          <span>|</span>
          <a href="#"> Privacy Policy</a>
          <a className="twitter" href="#">
            <span className="screen-reader-only">
              <img src={Insta} />
            </span>
          </a>
          <a className="instagram" href="#">
            <span className="screen-reader-only">
              <img src={Twitter} />
            </span>
          </a>
          <a className="instagram" href="#">
            <span className="screen-reader-only">
              <img src={Facebook} />
            </span>
          </a>
        </div>
      </NavBar>
    </>
  )
}

export default Footer
const FooterGrid = styled(Grid)`
  .MuiContainer-root {
    min-width: 1170px;
  }
  margin-top: 10px;

  padding: 40px 0;
  background-color: black;
  color: white;
  font-size: 12px;
  font-family: GT-Walsheim-Light;
  border-bottom: 1px solid white;
  button {
    color: white;
    background-color: #3b3b3b;
    //height: 10px;
    border-radius: 0;
    margin: 0 0 0 0;
    span {
      font-size: 12px;
    }
  }
  input {
    margin-top: 1px;
    height: 10px;
    color: black;
    font-family: GT-Walsheim-Light, sans-serif;
    font-size: 14px;
    background-color: white;
  }
  .links {
    //margin-left: 20%;
  }
  ul {
    color: white;
  }
  li {
    margin-bottom: 5px;
    list-style-type: none;
    color: white;
  }
  h4,
  h5,
  p {
    font-family: GT-Walsheim-Light;
    margin-bottom: 5px;
  }
`
