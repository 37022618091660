import styled from "styled-components"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import {
  FontFamily,
  FontFamilyText,
  FontFamilyTags,
  TextBgColor,
  TagsFont,
  MainTitleFont,
  SubTitleFont,
  TextFont,
} from "./constants"

const Title = styled.p`
  font-family: ${FontFamily};
  font-weight: 800;
  font-size: ${props => (props.small ? "18px" : "24px")};
  font-size: ${props => props.size};
  line-height: ${props => (props.small ? "20px" : "30px")};
  letter-spacing: ${props => (props.small ? "0.5px" : "")};
  color: ${TextBgColor};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "10px")};
`

const SectionTitle = styled.p`
  font-family: ${FontFamily};
  font-weight: 800;
  font-size: ${MainTitleFont};
  line-height: 1.1rem;
  color: ${TextBgColor};
  text-align: ${props => props.align};;
`
const SectionText = styled.p`
  font-weight: 100;
  font-size: ${props => props.size};
  font-family: ${FontFamilyText};
  line-height: 1.5rem;
  color: ${TextBgColor};
`
const SubTitle = styled.p`
  font-family: ${FontFamilyText};
  color: ${TextBgColor};
  font-size: ${SubTitleFont};
  line-height: 1.5;
  margin-bottom: -5px;
`
const TagsStyled = styled.p`
  font-family: ${FontFamilyTags};
  color: ${TextBgColor};
  font-size: ${TagsFont};
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 5px;
  span *:last-child {
    margin-bottom: -2px;
  }
  svg {
    font-size: 14px;
  }
`

const CategoryStyled = styled.p`
  font-family: ${FontFamilyTags};
  color: ${TextBgColor};
  color: ${TextBgColor};
  background-color: yellow;
  text-transform: uppercase;
  font-size: ${TagsFont};
  font-weight: 700;
  margin-bottom: 1px;
  //margin-top: -20px;
`

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  p {
    padding-right: 10px;
  }
`

const ShareStyled = styled(Button)`
  font-family: ${FontFamily};
  color: ${TextBgColor};
  font-weight: 700;
`

const ButtonStyled = styled(Button)`
  font-family: ${FontFamily};
  color: ${TextBgColor};
  min-width: 9px !important;
  padding-right: 0;
`
const Blog = styled.div`
  margin-top: 5%;
  letter-spacing: 2px;
  color: black;
  font-family: ${FontFamilyText};
  font-size: ${TextFont};
  line-height: 1.7rem;
`
const Wrapper = styled.div`
  padding: 0px 20% 0px 0%;
  @media (max-width: 768px) {
    padding: 0%;
  }
`

const TitleSocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
  }
`

const BorderedGrid = styled(Grid)`
  margin-bottom: 10px;
  border-right: solid 1px #c9c9c945;
`

const Line = styled.hr`
  background: #c9c9c945;
`

const FlatCard = styled(Card)`
  border: 0px;
  box-shadow: none;
  border-radius: 1px;
  background-color: #fbfcfc;
  padding: 0px 5% 0px 5%;
  .MuiCardContent-root,
  .MuiCardActions-root {
    padding: 10px 0 0 0;
  }

  .MuiButtonBase-root {
    padding: 5px 0 0 0;
  }
  img {
    object-fit: contain;
  }
  button {
    min-width: 2px !important;
  }
`

const SocialContainer = styled.span`
  display: flex;
  div {
    margin-bottom: 4px;
    padding-right: 5px;
    // padding-left:5px;
  }
`

//suggestion box
const SuggestionTitle = styled.h6`
  font-family: "GT-Walsheim-Regular", sans-serif;
  margin: 0 5%;
  padding: 10px 0;
  font-size: 18px;
  @media (max-width: 1407px) {
    margin: 0 1%;
  }
`

const SubSection = styled.div`
  display: flex;
  justify-content: space-between;
  h6 {
    font-style: normal;
    font-weight: 500;
  }
  h5 {
    font-family: "GT-Walsheim-Regular", sans-serif;
  }
  span {
    display: flex;
    //padding-right: 20%;
    color: black;
    h6 {
      padding-right: 5px;
    }
  }
`
const SuggestionsContainer = styled.div`
  border-radius: 5px;
  background-color: #ffff99;
  font-family: "GT-Walsheim-Regular", sans-serif;
  // padding: 0px 20% 0px 20%;
  a {
    color: black;
  }
  p {
    // margin:15px 0 10px 15px;
    padding: 0 0 0 25px;
  }
  @media (max-width: 768px) {
    //padding: 0%;
  }
`
const TagsContainer = styled.div`
  svg {
    font-size: 14px;
  }
  a {
    font-family: ${FontFamily};
    color: black;
  }
  margin-top: 40px;
  ul {
    list-style-type: none;
  }
`
export {
  SuggestionsContainer,
  SuggestionTitle,
  SubSection,
  TitleSocialWrapper,
  SocialContainer,
  Line,
  BorderedGrid,
  Wrapper,
  Blog,
  ShareStyled,
  ButtonStyled,
  TagContainer,
  TagsStyled,
  SubTitle,
  Title,
  FlatCard,
  SectionTitle,
  CategoryStyled,
  SectionText,
  TagsContainer,
}
