import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import React from "react"
import BlogPost from "../blogPost_md"
import { SectionTitle, BorderedGrid } from "../../components/styles/styles"
import { SectionText } from "../styles/styles"

const ContentSection = props => {
  //TODO: refactor this component to remove conditional data sharing
  const posts = props.data.posts ? props.data.posts : props.data
  // let imageFile = post.node.featuredImage.sourceUrl
  //   ? post.node.featuredImage.sourceUrl
  //   : "https://miaggrego.vinitalytour.com/wp-content/uploads/2020/02/image-placeholder.png"
  //

  return (
    <>
      <Link to={props.linkToPage}>
        <SectionTitle align={props.align}> {props.title}</SectionTitle>
        <SectionText size="18px"> {props.text}</SectionText>
      </Link>

      <Grid container spacing={1}>
        {posts.edges.slice(0, props.posts_on_homepage).map(post => (
          <BorderedGrid
            key={post.node.slug}
            item
            lg={props.lg}
            xs={props.xs}
            sm={props.sm}
          >
            <BlogPost style={props.style} data={post} />
          </BorderedGrid>
        ))}
      </Grid>
    </>
  )
}

export default ContentSection
