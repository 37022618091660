
// import firebase from 'firebase/app'
// import firestore from 'firebase/firestore'
// import auth from 'firebase/auth'

const firebase = require("firebase/app")
const auth = require("firebase/auth")

const firestore = require("firebase/firestore")

const firebaseConfig = {
    apiKey: "AIzaSyAVjrOO55z5AcC9_ngXEFzyZNtkL8OMDJw",
    authDomain: "miaggrego-279db.firebaseapp.com",
    databaseURL: "https://miaggrego-279db.firebaseio.com",
    projectId: "miaggrego-279db",
    storageBucket: "miaggrego-279db.appspot.com",
    messagingSenderId: "918885931265",
    appId: "1:918885931265:web:e0cd691e430ee72cc92300",
    measurementId: "G-8F56Z6CP7D"
};

// if (typeof window !== "undefined") {
//     firebase.initializeApp(firebaseConfig);
//     firebase.firestore().settings({ timestampsInSnapshots: true })
// }

if (typeof window !== "undefined") {
    // // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.firestore()

}

export default firebase;