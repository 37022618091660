import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Save from "@material-ui/icons/SaveAlt"
import LocalOffer from "@material-ui/icons/LocalOffer"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  getDatafromFirestore,
  setDataonFirestore,
} from "../redux/firebaseAction"
import {
  ShareStyled,
  ButtonStyled,
  TagContainer,
  TagsStyled,
  SubTitle,
  Title,
  FlatCard,
} from "../components/styles/styles"
import { CategoryStyled } from "./styles/styles"
const _ = require("lodash")

export default function BlogCard(props) {
  const { Error, isLoggedin, user } = useSelector(state => ({
    ...state.mainReducer,
    ...state.mainReducer.user.user,
  }))
  const dispatch = useDispatch()
  const getData = () => dispatch(getDatafromFirestore(user.user.email))
  const setonFirestore = () =>
    dispatch(
      setDataonFirestore(
        user.user.email,
        props.title,
        props.OptimizedImage.src,
        props.slug
      )
    )
  const RedirectAfterSignUp = () => {
    if (isLoggedin && !Error) {
      setonFirestore()
      getData()
      navigate("/dashboard")
    } else navigate("/dashboard")
  }

  const {
    frontmatter: { title, subheading, tags, subtitle },
    html,
    fields: { slug },
  } = props.data.node
  console.log("tagstag", tags ?? "")
  const image = props.data.node.frontmatter.featuredImage.childImageSharp.fixed
  const { style } = props
  const __tags = tags?.split(",")
  const tags_ = _.uniq(_.flattenDeep(__tags))

  return (
    <FlatCard>
      <CardMedia style={{ height: style.height }} title={title} src={title}>
        <Img
          imgStyle={{ objectFit: style.image }}
          style={{ width: "100%", height: "inherit" }}
          fixed={image}
        />
      </CardMedia>
      <CardContent>
        {props.data.node.frontmatter.category && (
          <CategoryStyled>
            {props.data.node.frontmatter.category}{" "}
          </CategoryStyled>
        )}
        <TagContainer>
          {__tags?.map?.(tag => (
            <Link to={`/tags/${_.kebabCase(tag)}/`}>
              <TagsStyled>
                <span>
                  <LocalOffer />
                  {tag}
                </span>
              </TagsStyled>
            </Link>
          ))}

          {/*  {Object.keys(props.tags).length > 0 &&*/}
          {/*    props.tags.map(tag => (*/}
          {/*      <TagsStyled key={tag.node.name}> #{tag.node.name} </TagsStyled>*/}
          {/*    ))}*/}
        </TagContainer>
        <Link to={slug}>
          <Title
            marginBottom="0px"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
        <SubTitle>{subtitle}</SubTitle>
      </CardContent>
      <CardActions style={{ justifyContent: "space-between" }}>
        <ButtonStyled
          aria-label="save for later"
          onClick={RedirectAfterSignUp}
          size="small"
          color="primary"
        >
          <Save />
        </ButtonStyled>

        <Link to={slug}>
          <ShareStyled>...</ShareStyled>
        </Link>
      </CardActions>
    </FlatCard>
  )
}
